import { useParams } from 'react-router-dom';
import test_post from '../../data/test_post';
import { Typography, Box, Container, Grid, Divider } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import Link from '@mui/material/Link';

const GradientBackground = styled('div')({
    background: `
      linear-gradient(
        to left,
        rgba(2, 14, 37, 1) 0%,
        rgba(2, 14, 37, 1) 100%
      )
    `,
  });

const ArticleTracker = styled('div')({
    background: `
      linear-gradient(
        to top,
        rgba(255, 255, 255, 0.0) 0%,
        rgba(255, 255, 255, 0.6) 30%,
        rgba(255, 255, 255, 0.6) 70%,
        rgba(255, 255, 255, 0.0) 100%
      )
    `,
    width: '1px',
    height: '40vh',
});

const ScrollDot = styled('div')({
    position: 'relative',
    //right: '10px', // or wherever you want to place it horizontally
    //top: '50%', // start in the middle of the screen vertically
    transform: 'translateX(-50%)',
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255)',
  });


const PostContent: React.FC = () => {
    const theme = useTheme();

    var limit = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight) - window.innerHeight;

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        // You can use window.scrollY for the vertical scroll position
        // Adjust the dot position based on the scroll
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const scrollToTarget = (event: any) => {
        event.preventDefault(); // Prevent the default anchor link behavior
        const targetId = event.currentTarget.getAttribute('href').slice(1); // Get the target id (removing the #)
        
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    



    return (
        <GradientBackground>
            <div style={{'display': 'flex', 'justifyContent': 'center'}}>
                <div style={{'flex': 1, 'display': 'flex', 'justifyContent': 'right'}}>
                    <div style={{'position': 'fixed', 'height': '100%', 'display': 'flex', 'flexDirection': 'column', 'alignItems': 'flex-start', margin: '2% 2% 0px 0px'}}>
                        <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignItems': 'center'}}>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '50%', margin: 20 }}>
                                <Link href="#target-header1" underline="none" onClick={scrollToTarget}>Go to Target Header</Link>
                                <Link href="#target-header2" underline="none" onClick={scrollToTarget}>Go to Target Header</Link>
                                <Link href="#target-header3" underline="none" onClick={scrollToTarget}>Go to Target Header</Link>
                                <Link href="#target-header4" underline="none" onClick={scrollToTarget}>Go to Target Header</Link>
                            </div>
                            <ArticleTracker/>
                            <div style={{height: '50%',}}>
                                <ScrollDot style={{ top: `${100 * scrollPosition / limit}%` }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{flex: 1}}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src="images/bp2.png" alt="description" style={{ maxWidth: '100%', maxHeight: '100%', margin: '5%', borderRadius: 10 }} />
                    </div>
                    <Typography variant="body1" color='white' style={{'flex': 1, 'margin': '2%'}}>
                        {test_post}
                    </Typography>
                </div>
                <div style={{'flexGrow': 1}} />
            </div>
        </GradientBackground>
    );
};

export default PostContent;