import React from 'react';
import { alpha } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

interface ArticleProps {
  id: number;
  title: string;
  imageUrl: string;
  description: string;
}

const ArticleCard: React.FC<ArticleProps> = ({ id, title, imageUrl, description }) => {
    return (
      <Link to={`/post/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <Card
            sx={{
                position: 'relative',
                maxWidth: 400,
                m: 0,
                borderRadius: 3,
                marginBottom: '2rem',
                overflow: 'visible',
                transition: 'transform 0.2s, box-shadow 0.2s',
                backgroundColor: 'rgba(2, 14, 37, 1.0)',
                zIndex: 1,
                '&:hover': {
                    transform: 'scale(1.13)',
                    //boxShadow: '0 4px 20px rgba(0,0,0,0.12)',
                    flex: '0 0 calc(33.33% - 2%)',
                    zIndex: 1000,
                    "& .MuiCardMedia-root": {
                        transform: 'translateY(-35%)',
                        zIndex: 1000,
                    },
                    "& .MuiCardContent-root": {
                        transform: 'translateY(20%)',
                        backgroundColor: 'rgba(4, 28, 74, 1.0)',
                        zIndex: 1000,
                    }
                }
            }}
            >
              <CardMedia
                  sx={{
                      width: '100%',
                      height: '100%',
                      //borderRadius: 4,
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      borderBottomLeftRadius: 12,
                      borderBottomRightRadius: 12,
                      transition: 'transform 0.2s'  // smooth transition for the slide effect
                  }}
                  component="img"
                  alt={title}
                  image={imageUrl}
              />
              <CardContent 
                  sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      transform: 'translateY(1px)',
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                      backgroundColor: 'rgba(2, 14, 37, 0.8)',
                      backdropFilter: 'blur(2px)',
                      transition: 'transform 0.2s, background-color 0.2s' // smooth transition for the slide effect
                  }}
              >
                  <Typography gutterBottom variant="h5" component="div" color='white'>
                      {title}
                  </Typography>
                  <Typography variant="body2" color="white">
                      {description}
                  </Typography>
              </CardContent>
          </Card>
      </Link>);
}

export default ArticleCard;