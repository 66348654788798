export type Post = {
  id: number;
  title: string;
  content: string;
  imageUrl: string;
  description: string;
};

const posts: Post[] = [
  {
    id: 1,
    title: "The Policy Gradient Theorem",
    content: "Your content here...",
    imageUrl: '/images/rl1.png',
    description: 'Building an intuitive understanding for one of the most important results in modern reinforcement learning.'
  },
  {
    id: 2,
    title: "Classical AI",
    content: "Your content here...",
    imageUrl: '/images/dt1.png',
    description: 'In a world of trillion parameter models, the classical techniques are still very relevant.'
  },
  {
    id: 3,
    title: "Beyond Backpropagation",
    content: "Your content here...",
    imageUrl: '/images/bp2.png',
    description: 'Moving past the most important algorithm in deep learning is difficult, but necessary.'
  },
  {
    id: 4,
    title: "Exotic Neural Networks",
    content: "Your content here...",
    imageUrl: '/images/nn2.png',
    description: 'Network topologies are priors. How are novel neural network architectures designed?'
  },
  {
    id: 5,
    title: "Complex Valued ML",
    content: "Your content here...",
    imageUrl: '/images/cpxnn1.png',
    description: 'Tackling the challenging task of moving machine learning algorithms into the complex domain.'
  },
  {
    id: 6,
    title: "Open Ended Evolution",
    content: "Your content here...",
    imageUrl: '/images/oee3.png',
    description: 'An often overlooked subfield of AI, open endedness aspires to unbounded complexity.'
  },
];

export default posts;

