import React from 'react';
import { useParams } from 'react-router-dom';
import posts from '../data/posts';
import { Typography, Box, Container, Grid, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type PostParams = {
  id: string;
};

const PostDetail: React.FC = () => {
    const { id } = useParams<PostParams>();
    const post = posts.find((p) => p.id === parseInt(id || "0"));
    const theme = useTheme();


    if (!post) return <Typography>No post found</Typography>;

    return (
        <Container>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={8} lg={6}>
                    <Box style={{ padding: theme.spacing(2), backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
                        <Typography variant="h4" style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(2) }}>
                            {post.title}
                        </Typography>
                        <Divider style={{ marginBottom: theme.spacing(2) }} />
                        <Typography variant="body1">
                            {post.content}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default PostDetail;