import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    mode: 'light', // This sets the theme mode to light
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: 'transparent', // Override the default background color
        },
      },
    },
  },
  // Add additional customizations if required
});

export default theme;