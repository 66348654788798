import React from 'react';
import { Divider, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useState, useEffect } from 'react';

const QuoteTypography = styled(Typography)({
    fontFamily: `'Georgia', serif`, // Using the Georgia font as an example.
    fontWeight: 'bold',  // Making it italic for added fanciness.
});

const GradientBackground = styled('div')({
  background: `
    linear-gradient(
      to left,
      rgba(203, 213, 221, 1) 0%,
      rgba(203, 213, 221, 0.1) 100%
    )
  `,
  width: '100%',
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const CustomDivider = styled('div')({
    background: `
      linear-gradient(
        to top,
        rgba(0, 24, 45, 0.0) 0%,
        rgba(0, 24, 45, 0.4) 30%,
        rgba(0, 24, 45, 0.4) 70%,
        rgba(0, 24, 45, 0.0) 100%
      )
    `,
    width: '1px',
    alignSelf: 'center', // To vertically center the divider within its parent container
    margin: '0 2%',
    height: '80%'
});

type ChevronDownProps = {
    isClickable?: boolean;
  };
  
const ChevronDown = styled('div')<ChevronDownProps>`
width: 40px;
height: 40px;
position: relative;
bottom: 2%;  // adjust based on where you want it on the title page
transition: opacity 0.0s, transform 0.3s;
cursor: ${props => props.isClickable ? 'pointer' : 'default'};
pointer-events: 'none';

&::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 50%;
    height: 2px;
    background-color: rgba(0, 24, 45, 0.4);
    transition: background-color 0.3s;  // This line makes the color change gradually
}

&::before {
    left: 0;
    transform: skewY(45deg);
}

&::after {
    right: 0;
    transform: skewY(-45deg);
}

:hover {
    transform: scale(1.2);
    &::before, &::after {
        background-color: rgba(0, 24, 45, 1.0); // This line changes the color to red upon hover
    }
}
`;

type TitlePageProps = {
    opacity: number;
    isClickable: boolean;
    onArrowClick: () => void;
};

const TitlePage: React.FC<TitlePageProps> = ({ opacity, isClickable, onArrowClick }) => {
  return (
    <GradientBackground style={{ display: 'flex', height: '100vh' }}>
        
        {/* Quote & Name Section */}
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <QuoteTypography variant="h3" color="rgba(0, 24, 45, 0.8)" style={{ marginBottom: '2%', width: '75%', textAlign: 'center' }}>
            We'll show you that you can build a mind from many little parts, each mindless by itself.
            </QuoteTypography>
            <Typography color="rgba(0, 24, 45, 0.3)">
            "The Society of Mind" - Marvin Minsky, 1986
            </Typography>
        </div>
        
        {/* Vertical Divider */}
        <div style={{ flex: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <CustomDivider style={{ margin: 'auto', marginTop: '10vh' }} />
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'bottom', height: '5%', marginTop: 'auto' }}>
                <ChevronDown style={{ opacity: opacity }} onClick={onArrowClick} isClickable={isClickable} />
            </div>
        </div>
        
        {/* Image Section */}
        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src="images/title_image2.png" alt="description" style={{ maxWidth: '90%', maxHeight: '90%' }} />
        </div>

    </GradientBackground>
  );
}

export default TitlePage;