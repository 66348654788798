import React from 'react';
import ArticleCard from './ArticleCard';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import posts from '../data/posts';
import TitlePage from './TitlePage';
import { useState, useEffect } from 'react';

const QuoteTypography = styled(Typography)({
  fontFamily: `'Georgia', serif`, // Using the Georgia font as an example.
  fontWeight: 'bold',  // Making it italic for added fanciness.
});

const CustomDivider = styled('div')({
  background: `
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0.0) 0%,
      rgba(255, 255, 255, 0.6) 30%,
      rgba(255, 255, 255, 0.6) 70%,
      rgba(255, 255, 255, 0.0) 100%
    )
  `,
  width: '1px',
  alignSelf: 'center', // To vertically center the divider within its parent container
  margin: '0 2%',
  height: '80%'
});

const GradientBackground = styled('div')({
  background: `
    linear-gradient(
      to left,
      rgba(2, 14, 37, 1) 0%,
      rgba(2, 14, 37, 1) 100%
    )
  `,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});


const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  overflowY: 'scroll',
});

const HeaderSection = styled('div')({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const ArticlesSection = styled('div')({
  padding: '0px',
});

const PostList = () => {
  const [opacity, setOpacity] = useState(1);
  const [isClickable, setIsClickable] = useState(true);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const articlesSectionRef = React.useRef<HTMLDivElement | null>(null); // New ref for articles section

  const handleArrowClick = () => {
    if (articlesSectionRef.current) {
      articlesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        let newOpacity = 1 - containerRef.current.scrollTop / 800;
        if (newOpacity < 0) newOpacity = 0;
        setOpacity(newOpacity);
      }
    };

    containerRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      containerRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <HeaderSection>
        <TitlePage opacity={opacity} isClickable={isClickable} onArrowClick={handleArrowClick} />
      </HeaderSection>
      <ArticlesSection ref={articlesSectionRef}>
        <GradientBackground style={{ display: 'flex', minHeight: '100vh', }}>

          {/* Image Section */}
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '50%' }}>
              <img src="images/title_image4.png" alt="description" style={{ maxWidth: '90%', maxHeight: '90%' }} />
          </div>
          
          {/* Vertical Divider */}
          <div style={{ flex: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
              <CustomDivider style={{ margin: 'auto' }} />
          </div>

          {/* Article Cards Section */}
          <div style={{ 
              flex: 1, 
              display: 'flex', 
              flexDirection: 'row',
              flexWrap: 'wrap',  // This allows the items to wrap onto the next row
              gap: '3%', 
              padding: '1%',
              justifyContent: 'center', // This will center the cards horizontally
              alignItems: 'center',     // This will center the cards vertically
          }}>
            {posts.map(post => (
              <ArticleCard 
                key={post.id} 
                id={post.id} 
                title={post.title} 
                imageUrl={post.imageUrl}
                description={post.description}
              />
            ))}
          </div>

        </GradientBackground>
      </ArticlesSection>
    </Container>
);
}

export default PostList;