import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import PostList from './components/PostList';
import TitlePage from './components/TitlePage';
import PostDetail from './components/PostDetail';
import PostContent from './components/post/PostContent';

import { Typography } from '@mui/material';
import { styled } from '@mui/system';



const App = () => {
  return (
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/experiment" element={<PostContent />} />
          <Route path="/post/:id" element={<PostDetail />} />
          <Route path="/" element={<PostList />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;